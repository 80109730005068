import React from 'react'
import { createComponent } from '@ycos/fela'

const CarouselContainer = createComponent(
    'CarouselContainer',
    ({ theme}) => ({
        marginTop: `-${theme.spacingMultiplier * 6}px`
    }),
    'div'
)
export const CMCarouselCollection = ({item, children})=> {
    if(item.items) {
        return children
    }
    return <CarouselContainer />
}

CMCarouselCollection.layoutVariant = ["osc-curated-carousel-collection", "osc-optional-collection"]

