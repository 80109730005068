export const getPersonalizedProductApiUrl = ({ wcs, customerId, locale }) => {
  const wcsUrlConfig = typeof window === 'undefined' ? wcs.baseUrl : wcs.externalBaseUrl
  const baseUrl = `${wcsUrlConfig.baseUrl}${wcs.personalizedWhatsNewPath}`
  const path = baseUrl.replace('{{storeId}}', `theoutnet_${locale.country}`.toUpperCase()).replace('{{customerId}}', customerId)

  return path
}

export const getProductApiUrl = ({ params, showMorePageNumber, locale, wcs }) => {
  const brandSite = `theoutnet_${locale.country}`
  const { seoUrl, sort, productsPerPage, offset } = params
  const localeKey = locale ? locale.locale : 'en_GB'
  const wcsUrlConfig = typeof window === 'undefined' ? wcs.internal : wcs.external
  const baseUrl = `${wcsUrlConfig.baseUrl}${wcs.productsByCategoryPath}`
  const pageNumber = showMorePageNumber || parseInt(offset) || 1
  const path = baseUrl
    .replace('{{brandSite}}', brandSite)
    .replace('{{seoUrl}}', seoUrl)
    .replace('{{pageSize}}', productsPerPage || 4)
    .replace('{{pageNumber}}', pageNumber)
    .replace('{{locale}}', localeKey)

  if (sort) {
    return `${path}&orderBy=${sort}`
  }

  return path
}
