import { Analytics } from '@ycos/analytics'
import { getCampaignEntry } from '../analytics-track'

const filterStockBadges = (badges) => {
  return badges?.reduce((acc, badge) => {
    if (badge.type === 'STOCK') {
      acc.push(badge)
    }
    return acc
  }, [])
}

const getProductAnalyticData = (product, index, item, dataSource) => {
  const {
    masterCategory,
    partNumber,
    nameEN,
    name,
    designerNameEN,
    designerName,
    designerIdentifier,
    price,
    onSale,
    badges,
    productColours,
    attributes
  } = product

  const attributesObject = attributes.reduce((acc, curr) => {
    acc[curr.identifier] = curr
    return acc
  }, {})

  return {
    category: {
      primaryCategory: masterCategory?.labelEN || masterCategory?.label,
      subCategory1: masterCategory?.child?.labelEN || masterCategory?.child?.label,
      subCategory2: masterCategory?.child?.child?.labelEN || masterCategory?.child?.child?.label
    },
    productInfo: {
      productID: `${partNumber}`,
      name: nameEN || name,
      designer: designerNameEN || designerName,
      designerID: designerIdentifier,
      productListName: item.subTitlePlain || item.teaserTitle
    },
    attributes: {
      position: index + 1,
      badges,
      colour: productColours[0]?.labelEN,
      department: attributesObject?.Department?.values[0]?.label,
      discountPercent: price.discount ? `${price?.discount?.amount / price?.discount?.divisor}%` : '0',
      saleFlag: !onSale ? 'full price' : 'on sale',
      stock: badges && badges[0].type === 'STOCK' ? badges[0]?.key : 'IN_STOCK',
      productSuggestionType: dataSource,
      season: attributesObject['Sale Season']?.values[0].label
    },
    price: {
      currency: price?.currency?.label,
      baseFullPrice: `${price?.wasPrice?.amount / price?.wasPrice?.divisor}`,
      basePrice: `${price?.sellingPrice?.amount / price?.sellingPrice?.divisor}`,
      basePriceGBP: null
    }
  }
}

export const WhatsNewAnalyticsRule = ({ item, legacyAnalytics = false, brand = { brandId: 'mrp' } }) => {
  if (item.layoutVariant === 'isc-whats-new-personalized' || item.layoutVariant === 'isc-whats-new') {
    const analytics = new Analytics({ isLegacy: legacyAnalytics, brand: brand.brandId })
    const hrefContainsAnalyticsString = item?.href && item?.href.indexOf('cm_sp=') !== -1
    const campaignInfo = hrefContainsAnalyticsString ? getCampaignEntry(item.href) : null

    return {
      item,
      campaignInfo,
      onProductLoad: (products, dataSource) => {
        const productsData = {
          siteMerchandising: [],
          items: []
        }
        products.forEach((product, index) => {
          productsData.siteMerchandising.push({
            placement: campaignInfo?.placement,
            position: campaignInfo?.position,
            campaignName: campaignInfo?.campaignName,
            campaignStartDate: campaignInfo?.campaignStartDate,
            productSuggestionType: `product${index + 1}-${dataSource}`
          })
          productsData.items.push(getProductAnalyticData(product, index, item, dataSource))
        })
        analytics.trackEvent({
          event: 'site merchandising - product load',
          effect: 'load products within site merchandising campaigns',
          category: {
            primaryCategory: 'site merchandising',
            subCategory: 'homepage'
          },
          attributes: {
            siteMerchandising: productsData.siteMerchandising
          },
          listing: {
            items: productsData.items
          }
        })
      },
      onProductClick: (product, index, dataSource) => {
        const { nameEN, partNumber, masterCategory, badges } = product
        const stockBadges = filterStockBadges(badges)
        const productStock = stockBadges?.length ? stockBadges[0]?.label : ''

        analytics.trackEvent({
          event: 'site merchandising - click',
          effect: 'campaign click',
          category: {
            primaryCategory: 'site merchandising',
            subCategory: 'homepage'
          },
          attributes: {
            siteMerchandising: [
              {
                placement: campaignInfo?.placement,
                position: campaignInfo?.position,
                campaignName: campaignInfo?.campaignName,
                campaignStartDate: campaignInfo?.campaignStartDate,
                productSuggestionType: `product${index + 1}-${dataSource}`
              }
            ]
          }
        })

        analytics.trackEvent({
          event: `view product - ${nameEN}`,
          eventEffect: 'go to product page',
          category: {
            primaryCategory: 'ecommerce',
            subCategory: 'homepage'
          },
          attributes: {
            productFindingMethod: 'homepage',
            position: index + 1
          },
          item: [
            {
              productInfo: {
                productID: partNumber,
                primaryCategory: masterCategory?.labelEN,
                subcategory1: masterCategory?.child?.labelEN,
                productListName: item?.subTitlePlain || item.teaserTitle,
                productStock
              },
              attributes: {
                productFindingMethod: 'homepage',
                productSuggestionType: dataSource
              }
            }
          ]
        })
      },
      onNavigationClick: (direction, position) => {
        analytics.trackEvent({
          event: `product carousel - ${direction}`,
          eventEffect: 'Interact with the product carousel on homepage',
          category: {
            primaryCategory: 'ecommerce',
            subCategory: 'homepage'
          },
          attributes: {
            position
          }
        })
      }
    }
  }
}
