import React from 'react'
import { useUserInfo } from '@ycos/user-information'
import { USERINFO_STATUS } from '@ycos/constants'
import { createComponent } from '@ycos/fela'
import { NextRouter, useRouter } from 'next/router'
import { shimmerAnimation } from '../component-product-list/Animaton'
import { Item }  from '../../domain/types/CoreMediaItem'
import { Collection } from '../../domain/types/CoreMediaCollection'
import { getComponentBySegment, SEGMENTED_EXPERIENCE_VARIANT } from './utils'

const LoadingContainer = createComponent(
  'LoadingContainer',
  ({ isLoaded, theme }) => ({
    position: 'relative',
    ':before': {
      display: isLoaded ? 'none' : 'block',
      content: '" "',
      background: theme.typography.colors.SecondaryLightestGrey,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      ...shimmerAnimation,
      'screen-medium': {
        margin: `0 ${theme.spacingMultiplier * 4}px`,
      }
    },
  }),
  'div'
)

const WrapperContainer = createComponent(
  'WrapperContainer',
  ({ isLoaded }) => ({
    opacity: isLoaded ? 1 : 0
  }),
  'div'
)

type SegmentedExperienceProps = {
  item: Collection
  children: React.ReactNode[]
  isPreview: boolean
}

type CoreMediaChild = {
  props: {
    item: Item
  }
}

const getSegmentsFromParams = (segments?:  string[] | string): string[] | undefined => {
  if(typeof segments === 'string') {
   return segments.split(',')
  }
  return segments
}

export function SegmentedExperience({ item, children, isPreview }: SegmentedExperienceProps) {
  const userInfoState = useUserInfo()
  const { userInfo, userInfoStatus } = userInfoState

  const router: NextRouter = useRouter()
  const queryParamSegments = getSegmentsFromParams(router.query.segments)

  const userSegments: string[] = (isPreview && queryParamSegments) || userInfo?.marketingGroups || []

  const isLoaded = userInfoStatus === USERINFO_STATUS.LOADED

  const coremediaItem = getComponentBySegment(item.items, isLoaded ? userSegments : [] )
  const component = React.Children.toArray(children).find(
    (child: CoreMediaChild) => child.props.item.contentID === coremediaItem.contentID)

  return (
    <LoadingContainer isLoaded={isLoaded}>
      <WrapperContainer isLoaded={isLoaded}>{component}</WrapperContainer>
    </LoadingContainer>
  )
}

SegmentedExperience.layoutVariant = SEGMENTED_EXPERIENCE_VARIANT

export default SegmentedExperience
