import React from 'react'
import { createComponent } from '@ycos/fela'
import { hasTag, getTags } from '@ycos/coremedia-renderer'
import VisibilityWrapper from '../component-visibility'
import { Picture } from '@ycos/picture'
import { HeadingLarge, Link } from '@ycos/primitives'

const MediaContainer = createComponent(
  'MediaContainer',
  ({ theme, isFullWidth, isFullBleed }) => {
    let padding = 4 * theme.spacingMultiplier

    if (isFullBleed || isFullWidth) {
      padding = 0
    }

    return {
      marginBottom: `${2.5 * theme.spacingMultiplier}px`,
      width: isFullBleed ? 'calc(100vw - ((100vw - 100%)/2))' : 'auto',
      position: 'relative',
      transform: isFullBleed ? 'translate(-50%, 0)' : 'initial',
      left: isFullBleed ? '50%' : 'initial',
      maxWidth: isFullBleed ? `${theme.breakpoints.large.width}px` : 'initial',
      'screen-medium': {
        paddingRight: `${padding}px`,
        paddingLeft: `${padding}px`
      }
    }
  },
  'div'
)

const TitleContainer = createComponent(
  'TitleContainer',
  ({ theme }) => ({
    paddingBottom: `${0.25 * theme.spacingMultiplier}px`,
    'screen-medium': {
      marginRight: `${4 * theme.spacingMultiplier}px`,
      marginLeft: `${4 * theme.spacingMultiplier}px`
    },
    borderBottom: '1px solid #e9e9e9'
  }),
  'div'
)

const TextContainer = createComponent(
  'TextContainer',
  () => ({
    textAlign: 'center'
  }),
  'div'
)
const LinkContainer = createComponent(
  'LinkContainer',
  ({ theme }) => ({
    margin: `${2 * theme.spacingMultiplier}px 0`,
    '& > a': {
        border: 'none',
        ':hover': {
            color: 'rgba(0,0,0,1)',
            border: 'none',
          }
      }

  }),
  'div'
)

const PreHomePageBlock = (props) => {
  const { config, onTargetClick } = props
  const { teaserTitle, subTitlePlain, pictures = [], videos = [], targets = [], tags = '', anchorpoint } = props.item

  const isFullWidth = hasTag(tags, 'Content.FullWidth')
  const isFullBleed = hasTag(tags, 'Image.FullBleed')
  const isColorInverse = hasTag(tags, 'Product.InverseColor')
  const hasVideo = videos.length > 0
  const hasMedia = !!(hasVideo || pictures.length)
  const isDeviceSpecific = getTags(tags, 'DeviceType')
  return (
    <VisibilityWrapper isDeviceSpecific={isDeviceSpecific} data-automation="pre-homepage-block-component">
      {hasMedia && (
        <MediaContainer data-automation="media-container" isFullWidth={isFullWidth} isFullBleed={isFullBleed}>
          <Link href={`/${props?.locale?.id}${anchorpoint}`} onClick={onTargetClick}>
            <Picture images={pictures} imagesphere={config.imageSphere} />
          </Link>
        </MediaContainer>
      )}
      <TextContainer>
        <TitleContainer>
          <Link href={`/${props?.locale?.id}${anchorpoint}`} onClick={onTargetClick}>
            <HeadingLarge inverseColor={isColorInverse}>{teaserTitle}</HeadingLarge>
          </Link>
        </TitleContainer>
        {targets.length > 0 &&
          targets.map((target, key) => {
            const { type, categoryLink, pageURL, title } = target.target || {};
            const href = type === 'augmentedCategory' ? categoryLink : pageURL;
            return (
              <LinkContainer key={key}>
                <Link href={href} onClick={onTargetClick}>
                  {title || subTitlePlain}
                </Link>
              </LinkContainer>
            )
          })}
      </TextContainer>
    </VisibilityWrapper>
  )
}

PreHomePageBlock.layoutVariant = 'osc-pre-homepage-block'

export default PreHomePageBlock
