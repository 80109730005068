import React from 'react'
import WhatsNewCarousel from '@ycos/component-carousel-whats-new'
import { useUserInfo } from '@ycos/user-information'
import { createComponent } from '@ycos/fela'
import { hasTag } from '@ycos/coremedia-renderer'
import { getPersonalizedProductApiUrl, getProductApiUrl } from './ProductUtils'

const CAROUSEL_MAX_PRODUCTS = 12

export const Wrapper = createComponent(
  'Wrapper',
  ({ theme }) => ({
    display: 'block',
    maxWidth: 'min(100vw, 1376px)',
    'screen-large': {
      margin: `0 ${theme.spacingMultiplier * 4}px`,
      padding: '0'
    },
    paddingLeft: '12px'
  }),
  'div'
)

const WhatsNew = (props) => {
  const {
    item,
    productsData,
    urlConfig,
    locale,
    brand,
    onClick,
    onProductClick,
    config,
    isComponentMatched,
    onNavigationClick,
    onProductLoad,
    campaignInfo,
    lazy
  } = props

  const userInfoState = useUserInfo()
  const { userInfo } = userInfoState
  const { dataCmMetadata, fetchPriority, tags } = item
  const invertedCTAButton = hasTag(tags, 'CTAButton.Inverted') && brand?.brandId === 'theoutnet'

  const apiUrl = item.layoutVariant === 'isc-whats-new-personalized' ? getPersonalizedProductApiUrl : getProductApiUrl

  return (
    <div data-cm-metadata={dataCmMetadata}>
      <Wrapper>
        <WhatsNewCarousel
          fetchPriority={fetchPriority}
          item={item}
          productsData={productsData}
          locale={locale}
          brand={brand}
          onTargetClick={onClick}
          onProductClick={onProductClick}
          config={config}
          isComponentMatched={isComponentMatched}
          customerId={userInfo?.parentCustomerURN}
          getProductApiUrl={apiUrl}
          maxProducts={CAROUSEL_MAX_PRODUCTS}
          onNavigationClick={onNavigationClick}
          onProductLoad={onProductLoad}
          urlConfig={urlConfig}
          campaignInfo={campaignInfo}
          lazy={lazy}
          invertedCTAButton={invertedCTAButton}
        />
      </Wrapper>
    </div>
  )
}

WhatsNew.layoutVariant = ['isc-whats-new-personalized', 'isc-whats-new']

export default WhatsNew
