import React from 'react'
import { Grid } from '@ycos/component-grid'
import { createComponent } from '@ycos/fela'
import { hasTag, getTags } from '@ycos/coremedia-renderer'
import VisibilityWrapper from '../component-visibility'

const Container = createComponent(
    'Container',
    ({ textWidthAligned }) => {
      return {
        width: textWidthAligned ? '326px' : '100%',
        '& button': {
          maxWidth: textWidthAligned ? 'none' : '326px'
        },
        ...(textWidthAligned && {
          '& > div': {
            paddingLeft: 0,
            paddingRight: 0
          }
        })
      }
    },
    'div'
  )

const PreHomePageCollection = (props) => {
    const { tags } = props.item
    const alignTop = hasTag(tags, 'Content.AlignTop')
    const alignMiddle = hasTag(tags, 'Content.AlignMiddle')
    const alignBottom = hasTag(tags, 'Content.AlignBottom')
    const textWidthAligned = hasTag(tags, 'Content.TextWidthAligned')
    const isDeviceSpecific = getTags(tags, 'DeviceType')
    return (
      <VisibilityWrapper isDeviceSpecific={isDeviceSpecific}>
        <Grid.Row>
          <Grid.Span
            additionalStyles={{
              marginTop: '0px',
              display: 'flex',
              alignItems: (alignTop && 'flex-start') || (alignMiddle && 'center') || (alignBottom && 'flex-end')
            }}
            small={{ width: 3, offset: 0 }}
            medium={{ width: 6, offset: 0 }}
          >
            <Container data-testid="column-0-container">{props.children[0]}</Container>
          </Grid.Span>
          <Grid.Span
            additionalStyles={{
              'screen-medium': { marginTop: '0' },
              display: 'flex',
              alignItems: (alignTop && 'flex-start') || (alignMiddle && 'center') || (alignBottom && 'flex-end'),
              ...(textWidthAligned && { justifyContent: 'center' })
            }}
            small={{ width: 3, offset: 0 }}
            medium={{ width: 6, offset: 0 }}
          >
            <Container data-testid="column-1-container" textWidthAligned={textWidthAligned}>
              {props.children[1]}
            </Container>
          </Grid.Span>
        </Grid.Row>
      </VisibilityWrapper>
    )
}

PreHomePageCollection.layoutVariant = 'osc-pre-homepage-collection'

export default PreHomePageCollection
